// src/App.jsx
import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { observer } from 'mobx-react'; 
import { useUserStore } from './hooks/useStores';
import AppLayout from './components/AppLayout';
import PublicLandingPage from './views/PublicLandingPage';
import PrivateLandingPage from './views/PrivateLandingPage';
import TwitterAuthFailPage from './views/TwitterAuthFailPage';
import Table from './views/Table'; 
import PlaygroundSandbox from './views/PlaygroundSandbox';
import PlaygroundBalance from './views/PlaygroundBalance';
import PlaygroundCounter from './views/PlaygroundCounter';
import PlaygroundSendToL1 from './views/PlaygroundSendToL1';
import NetworkSwitcherApp from './views/NetworkSwitcher';
const TestComponent = () => <div>Test Component</div>;
const LoadingComponent = () => <div>Verifying token...</div>;

const App = observer(() => { 
  // Diagnostics
  const showTest = false; // Toggle this to switch components
  
  // Stores & contexts
  const userStore = useUserStore();
  
  // Check if running locally
  // const isLocal = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1';
  
  // Check signin status
  console.log(`CHIPS App loaded`);
  console.log(`${userStore.user?.username ? "Signed in user: " + userStore.user.username + ". Access granted" : "No user signed in, access limited"}`);
  
  return (
    //<ViewportHeightDemo/>
    <AppLayout>
      <Routes>
        {/* Conditionally show a loading or test component, or proceed to regular routing if not loading */}
        {userStore.isLoadingToken && <Route path="*" element={<LoadingComponent />} />}
        
        {/* When not loading, define regular application routes */}
        {!userStore.isLoadingToken && (
          <>
            {/* Define public routes */}
            <Route path="/login" element={showTest ? <TestComponent /> : <PublicLandingPage/>} />
            <Route path="/auth-fail" element={showTest ? <TestComponent /> : <TwitterAuthFailPage />} />
            
            {/* Protected route: Check if user is authenticated or running locally */}
            {userStore.user.username ? (
            //{(userStore.user.username || isLocal) ? (
              <>
                {/* User-specific routes */}
                <Route path="/" element={showTest ? <TestComponent /> : <PrivateLandingPage />} />
                <Route path="/table/:tableNumber" element={showTest ? <TestComponent /> : <Table />} />
                <Route path="/playgroundSandbox" element={showTest ? <TestComponent /> : <PlaygroundSandbox />} />
                <Route path="/playgroundBalance" element={showTest ? <TestComponent /> : <PlaygroundBalance />} />
                <Route path="/playgroundCounter" element={showTest ? <TestComponent /> : <PlaygroundCounter />} />
                <Route path="/playgroundSendToL1" element={showTest ? <TestComponent /> : <PlaygroundSendToL1 />} />
                <Route path="/network" element={showTest ? <TestComponent /> : <NetworkSwitcherApp />} />
              </>
            ) : (
              <>
                {/* Redirect unauthenticated users */}
                <Route path="/table/:tableNumber" element={showTest ? <TestComponent /> : <Navigate to="/login" replace />} />
                <Route path="/" element={showTest ? <TestComponent /> : <Navigate to="/login" replace />} />
              </>
            )}
            {/* Fallback route for unmatched paths, typically placed at the end */}
            <Route path="*" element={<Navigate to="/login" replace />} />
          </>
        )}
      </Routes>
    </AppLayout>
  );
});

export default App;