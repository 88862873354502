// src/lib/isc/services/clients.ts
import { createWalletClient, createPublicClient, custom, http } from "viem";
import { createBundlerClient } from 'viem/account-abstraction'
//import { chips } from '@isc/client/chipsChain'; // Assuming you exported `chips` from `chains.ts`
import { chips } from "viem/chains"
//import { mainnet, polygonMumbai } from "viem/chains";

export const publicClient = createPublicClient({
  //chain: mainnet, // or use polygonMumbai for a test network
  chain: chips, // Use the custom Chips Network chain
  transport: http(),
});

export const walletClient = window.ethereum ? createWalletClient({
    //chain: polygonMumbai,
    chain: chips, // Use the custom Chips Network chain
    transport: custom(window.ethereum),
  })
  : (() => {
    throw new Error("Ethereum provider not found. Please install MetaMask.");
  }
)();

  // const bundlerClient = createBundlerClient({ 
  //   publicClient, 
  //   transport: http('https://public.pimlico.io/v2/1/rpc'), 
  // })