import styled from 'styled-components';

export const PrivateLandingContainer = styled.div`
  // Position & size
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 100;
  
  // Internal layout
  display: flex;
  flex-direction: column;
  justify-content: center;
  
  // Styling
  text-transform: uppercase;
  word-break: break-word;
  align-items: center;
`;

export const Table = styled.div`
  // Position & size
  position: absolute;
  top: 30dvh;
  left: 50%;
  transform: translate(-50%, -0%);
  z-index: 100;
  
  // Internal layout
  display: flex;
  flex-direction: column;
  justify-content: center;
  
  // Styling
  text-transform: uppercase;
  word-break: break-word;
  align-items: center;
  
  // Styles for tables within this container
  table {
    width: 100%; /* Ensure the table expands to the width of its container */
    table-layout: auto; /* Use 'auto' to allow the table to expand based on content */
  }
  
  th, td {
    white-space: nowrap; /* Prevent text from wrapping */
    overflow: hidden; /* Hide text that overflows the cell's bounds */
    text-overflow: ellipsis; /* Show ellipsis (...) for text that overflows */
  }
`;