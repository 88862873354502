import React from 'react';
import { Link } from 'react-router-dom';

const TwitterAuthFailPage = () => {
  return (
    <div>
      <h1>Twitter Authentication Failed</h1>
      <p>Sorry, we couldn't authenticate you with Twitter. Please try again or use another method to log in.</p>
      <Link to="/login">Go Back to Login</Link>
    </div>
  );
};

export default TwitterAuthFailPage;