// src/views/PlaygroundCounter/index.tsx
import React, { useState, useEffect } from 'react';
import Button from '../../components/ButtonWithMargins';
import PlaygroundContainer from '../../components/PlaygroundContainer';
import CounterABI from './CounterABI.json'; // Import the ABI from JSON
import { PlaygroundContent } from './styles';
import { walletClient, publicClient } from '@isc/client/clients';

const CounterContractAddress = '0x175f5921f63fc8eac7362a38ea046aecf095f7ff'; // Replace with your deployed contract address

const CounterApp: React.FC = () => {
  const [account, setAccount] = useState<`0x${string}` | null>(null);
  const [contract, setContract] = useState<any>(null); // Use `any` type for the contract since Viem types may vary
  const [count, setCount] = useState<string | null>(null);

  useEffect(() => {
    if (account) {
      const contractInstance = {
        address: CounterContractAddress,
        abi: CounterABI,
      };
      setContract(contractInstance);
      getCount(contractInstance);
    }
  }, [account]);

  const connectMetaMask = async () => {
    try {
      const addresses = await walletClient.getAddresses();
      setAccount(addresses[0] as `0x${string}`);
    } catch (error) {
      console.error('Failed to connect wallet', error);
    }
  };

  const getCount = async (contractInstance: any) => {
    try {
      const currentCount = await publicClient.readContract({
        address: contractInstance.address,
        abi: contractInstance.abi,
        functionName: 'getCount',
        args: [], // Required args property, even if empty
      }) as bigint; // Assert the type as bigint since we're expecting a numeric value
      setCount(currentCount.toString()); // Convert BigInt to string
      console.log('Current count:', currentCount.toString());
    } catch (error) {
      console.error('Failed to get count', error);
    }
  };

  const increment = async () => {
    if (!contract || !account) return;

    try {
      const result = await walletClient.writeContract({
        address: contract.address,
        abi: contract.abi,
        functionName: 'increment',
        args: [], // Required args property, even if empty
        account,
        chain: contract.chain, // Ensure the correct chain is provided
      });
      console.log('Increment result:', result);
      getCount(contract); // Update count after increment
    } catch (error) {
      console.error('Failed to increment count', error);
    }
  };

  const decrement = async () => {
    if (!contract || !account) return;

    try {
      const result = await walletClient.writeContract({
        address: contract.address,
        abi: contract.abi,
        functionName: 'decrement',
        args: [], // Required args property, even if empty
        account,
        chain: contract.chain, // Ensure the correct chain is provided
      });
      console.log('Decrement result:', result);
      getCount(contract); // Update count after decrement
    } catch (error) {
      console.error('Failed to decrement count', error);
    }
  };

  return (
    <PlaygroundContent>
      <h1>Counter DApp</h1>
      <Button onClick={connectMetaMask}>Connect to MetaMask</Button>
      {account && (
        <>
          <p>Connected account: {account}</p>
          <p>Current Count: {count !== null ? count : 'Loading...'}</p>
          <Button onClick={increment} style={{ display: 'block', marginTop: '10px' }}>Increment</Button>
          <Button onClick={decrement} style={{ display: 'block', marginTop: '10px' }}>Decrement</Button>
          <Button onClick={() => getCount(contract)} style={{ display: 'block', marginTop: '10px' }}>Get Current Count</Button>
        </>
      )}
      <PlaygroundContainer />
    </PlaygroundContent>
  );
};

export default CounterApp;
